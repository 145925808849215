import axios from 'axios'
import firebase from 'firebase/app';
import { getAuth } from 'firebase/auth';
const BASE_URL = process.env.VUE_APP_API_URL;
//const BASE_URL = 'http://localhost:8080'

// 1. Create an axios instance that you wish to apply the interceptor to
export const axiosInstance = axios.create({ baseURL: BASE_URL })

axiosInstance.interceptors.request.use(config => {
  // config.headers['Content-Type'] = 'multipart/form-data'
  config.headers['Content-Type'] = 'application/json'
  if (getAuth().currentUser) {
    return getAuth().currentUser.getIdToken().then(function (token) {
      config.headers['Authorization'] = token;
      return config;
    })
  } else {
    return config;
  }
}, error => {
  console.log(error) // for debug
  Promise.reject(error)
})